<!--
 * @Author: 张博洋
 * @Date: 2021-08-10 13:53:11
 * @LastEditTime: 2022-02-25 14:38:06
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/drug/detail.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="wraper">
    <div class="detail">
      <drugStatusCard v-if="state != 1"
                      :state="state"
                      :updateTime="orderDetail.updateTime"
                      :cancleText="orderDetail.cancelMsg"
                      :payExpireTime="orderDetail.payExpireTime"
                      @changeState="changeState"></drugStatusCard>
      <!-- 只有state === 1 的可以购药 -->
      <van-form class="form"
                ref='form'>
        <div class="p"
             v-if="state == 1">
          <van-field v-model="distributionName"
                     name="取药方式"
                     label="取药方式"
                     right-icon="arrow"
                     input-align="right"
                     clickable
                     readonly
                     placeholder="请选择取药方式"
                     @click="drugWayPanelShow = true">
          </van-field>
          <van-field v-model="pharmacyName"
                     name="取药门店"
                     label="取药门店"
                     right-icon="arrow"
                     input-align="right"
                     clickable
                     readonly
                     :placeholder="pharmacyNamePlaceholder"
                     v-if="params.distributionType === 10"
                     @click="toStoreList">
          </van-field>
          <van-field name="选择配送地址"
                     label="选择配送地址"
                     right-icon="arrow"
                     input-align="right"
                     clickable
                     readonly
                     :placeholder="deliveryPlaceholder"
                     @click="addressListPanelShow = true"
                     v-if="params.distributionType === 20">
          </van-field>
          <div v-if="currentStoreInfo && params.distributionType === 10"
               class="store">
            <div class="hospital-name">
              <img :src="JSON.parse(currentStoreInfo).pharmacyPic"
                   alt="">
              <span>{{JSON.parse(currentStoreInfo).pharmacyName}}</span>
            </div>
            <p class="mt12">{{JSON.parse(currentStoreInfo).address}}</p>
            <p class="mt4">门店电话：{{JSON.parse(currentStoreInfo).tel}}</p>
          </div>

          <div class="select-address"
               v-if="params.distributionType === 20 && addressShow">
            <p style="display: flex; flex-wrap: wrap; align-items: center;">
              <span class="name mr12">{{selectAddress.userName}}</span>
              <span class="phone">{{selectAddress.userPhone}}</span>
            </p>
            <p class="adress mt8">{{selectAddress.provinceName + selectAddress.cityName + selectAddress.countyName}}</p>
            <p class="adress mt2">{{selectAddress.address}}</p>
          </div>
        </div>

        <div class="p have-sumbit"
             v-if="state != 1">
          <van-cell title="药品配送"
                    value="医百药房" />
          <van-cell title="取药方式"
                    :value="orderDetail.distributionType === 10 ?'药店自提' : '快递到家'" />
          <div v-if="orderDetail.distributionType === 10"
               class="store">
            <div class="hospital-name">
              <img :src="orderDetail.pharmacy.pharmacyPic"
                   alt="">
              <span>{{orderDetail.pharmacy.pharmacyName}}</span>
            </div>
            <p class="mt12">{{orderDetail.pharmacy.address}}</p>
            <p class="mt4">门店电话：{{orderDetail.pharmacy.tel}}</p>
          </div>

          <div class="select-address"
               v-if="orderDetail.distributionType === 20">
            <p style="display: flex; flex-wrap: wrap; align-items: center;">
              <span class="name mr12">{{orderDetail.deliveryAddress.userName}}</span>
              <span class="phone">{{orderDetail.deliveryAddress.userPhone}}</span>
            </p>
            <p class="adress mt8">{{orderDetail.deliveryAddress.provinceName + orderDetail.deliveryAddress.cityName + orderDetail.deliveryAddress.countyName}}</p>
            <p class="adress mt2">{{orderDetail.deliveryAddress.address}}</p>
          </div>

        </div>

        <div class="p code"
             v-if="state == 30">
          <p>请在提药时出示以下取药码</p>
          <div class="drug-code mt20">{{orderDetail.takeCode}}</div>
        </div>

        <div class="p-info">
          <div class="head flex">
            <h1>处方信息</h1>
            <van-button plain
                        hairline
                        round
                        size="mini"
                        class="check-pre"
                        color="#999"
                        @click="checkPre">查看处方</van-button>
          </div>

          <van-cell title="处方状态"
                    :value="getState" />
          <van-cell title="开方医生"
                    :value="preDetail.doctorName || orderDetail.doctorName" />
          <van-cell title="开方时间"
                    :value="$.replyTime(preDetail.createTime || orderDetail.createTime)" />
          <van-cell title="处方ID"
                    :value="preDetail.prescriptionId || orderDetail.prescriptionId" />
        </div>

        <div class="case-info mt8">
          <div class="head flex">
            <h1>费用明细</h1>
          </div>
          <van-cell class="price"
                    title="药品费"
                    :value="!preDetail.realPrice && !orderDetail.realPrice ? '' : `¥${preDetail.realPrice || orderDetail.realPrice}`" />
          <van-cell v-if="orderDetail.distributionType === 20 || params.distributionType === 20"
                    title="配送费"
                    value="到付，快递公司收取" />
        </div>

        <div class="order-info mt8 mb8"
             v-if="state != 1">
          <div class="head">
            <h1>订单明细</h1>
          </div>
          <van-cell title="订单ID"
                    :value="orderDetail.orderNo" />
          <van-cell title="下单时间"
                    :value="$.replyTime(orderDetail.commitTime)" />
          <van-cell title="支付流水号"
                    :value="orderDetail.paySerialNo" />
          <van-cell title="支付方式"
                    value="微信"
                    v-if="state != 40 || state != 50" />
        </div>

        <div class="tip"
             v-if="state == 1">
          特别提示 <br>
          如有任何疑问，请联系客服：400-200-1000。<br>
          根据《国家食品药品监督管理局令第28号令》第一百七十三条：除药品质量原因外，药品一经售出，不得退换。
        </div>
      </van-form>
      <DrugWayPanel :drugWayPanelShow="drugWayPanelShow"
                    @close="closeDrugWayPanelShow"></DrugWayPanel>
      <AddressListPanel :addressListPanelShow="addressListPanelShow"
                        :addressList="addressList"
                        @refreshAddressList="refreshAddressList"
                        @closeAddressPanel="closeAddressPanel"
                        @closePanel="closePanel"></AddressListPanel>
    </div>

    <div class="bottom mt8"
         v-if="state == 1 || state == 10 || state == 31 || state == 50">
      <service class="mt16"
               v-if="state == 1 && params.distributionType !== ''"
               :distributionType="params.distributionType"
               @checkboxEvent="handleAgree"></service>
      <div class="btn-group">
        <!-- rpExpired 0 未过期 -->
        <van-button v-if="state == 1 || (state == 50 && orderDetail.rpExpired === 0)"
                    color="#1AB370"
                    class="btn-order mt10"
                    @click="submitAction"
                    round>提交订单</van-button>
        <van-button v-if="state == 10"
                    class="btn-cancle mt10"
                    @click="cancleOrder"
                    round>取消订单</van-button>
        <van-button v-if="state == 10"
                    color="#FF8200"
                    class="btn-pay mt10"
                    @click="pay"
                    round>去支付</van-button>
        <van-button v-if="state == 31"
                    color="#1AB370"
                    class="btn-confirm mt10"
                    @click="confirmDrug"
                    round>收药确认</van-button>
      </div>
    </div>
  </div>

</template>

<script>
import forms from '@/mixin/Form.js'
import button from '@/mixin/Button.js'
import DrugWayPanel from './components/drugWayPanel.vue'
import AddressListPanel from './components/addressListPanel.vue'
import drugStatusCard from './components/drugStatusCard.vue'
import service from './components/service.vue'
import { Dialog } from 'vant'

export default {
  data() {
    return {
      id: this.$route.query.id || '',
      state: this.$route.query.state,
      drugWayPanelShow: false,
      addressListPanelShow: false,
      distributionName: '',
      pharmacyNamePlaceholder: '请选择取药门店',
      deliveryPlaceholder: '请选择配送地址',
      currentStoreInfo: '',
      pharmacyName: '',
      params: {
        distributionType: '',
        pharmacyId: '',
        deliveryId: '',
      },
      addressList: [],
      selectAddress: {},
      addressShow: false,
      preDetail: {},
      orderDetail: {},
      isAgree: false,
      cancleText: '超时未支付，购药订单已关闭。',
      timer: null,
    }
  },
  mixins: [forms, button],

  created() {
    console.log(this.id, this.state)
    // console.log(typeof this.state)
    // localStorage.removeItem('storeInfo')

    // console.log(JSON.parse(localStorage.getItem('storeInfo')))
    // // 获取带回来的门店信息
    // if (localStorage.getItem('storeInfo')) {
    //   alert('初始化')
    //   this.currentStoreInfo = localStorage.getItem('storeInfo')
    //   this.pharmacyName = ''
    //   this.pharmacyNamePlaceholder = ''
    //   this.params.pharmacyId = JSON.parse(this.currentStoreInfo).pharmacyId
    //   delete this.params.deliveryId
    //   console.log('=========', this.params)
    // }
    this.getAddressList()
    if (this.state === 1) {
      this.getPreDetail()
    } else {
      this.getdrugOrderDetail().then(() => {
        this.isAgree = true
        this.state = this.orderDetail.state
        this.params.distributionType = this.orderDetail.distributionType
        this.orderDetail.deliveryId
          ? (this.params.deliveryId = this.orderDetail.deliveryId)
          : delete this.params.deliveryId
        this.orderDetail.pharmacyId
          ? (this.params.pharmacyId = this.orderDetail.pharmacyId)
          : delete this.params.pharmacyId
      })
    }

    // this.timer = setInterval(() => {
    //   this.getdrugOrderDetail()
    // }, 5000)
  },
  activated() {
    this.getAddressList()
  },
  destroyed() {
    clearInterval(this.timer)
    localStorage.removeItem('storeInfo')
    this.currentStoreInfo = ''
  },
  watch: {
    state: {
      handler(val) {
        // alert(val)
        if (val === 20) {
          this.timer = setInterval(() => {
            this.getdrugOrderDetail()
          }, 3000)
        }
      },
    },
  },
  computed: {
    getState() {
      switch (this.preDetail.state || this.orderDetail.state) {
        case 1:
          return '已开具'
        case 5:
          return '已失效'
        case 10:
          return '待支付'
        case 20:
          return '待打包'
        case 30:
          return '待取药'
        case 31:
          return '待收药'
        case 40:
          return '已完成'
        case 50:
          return '已取消'
      }
    },
  },

  activated() {
    // alert('缓存')
    console.log(JSON.parse(localStorage.getItem('storeInfo')))
    // 获取带回来的门店信息
    if (localStorage.getItem('storeInfo')) {
      this.currentStoreInfo = localStorage.getItem('storeInfo')
      this.pharmacyName = ''
      this.pharmacyNamePlaceholder = ''
      this.params.pharmacyId = JSON.parse(this.currentStoreInfo).pharmacyId
      delete this.params.deliveryId
      console.log('=========', this.params)
    }
  },
  components: {
    DrugWayPanel,
    AddressListPanel,
    drugStatusCard,
    service,
  },
  methods: {
    submitAction() {
      console.log(this.params)
      if (this.state == 1) {
        if (!this.params.distributionType) {
          this.$.toast.warning('请选择取药方式')
          return
        } else {
          if (this.params.distributionType === 10) {
            if (!this.params.pharmacyId) {
              this.$.toast.warning('请选择取药门店')
              return
            }
          }
          if (this.params.distributionType === 20) {
            if (!this.params.deliveryId) {
              this.$.toast.warning('请选择配送地址')
              return
            }
          }
          if (!this.isAgree) {
            this.$.toast.warning('请先同意药品配送服务协议')
            return
          }
          this.params.id = this.id
          this.$axios({
            type: 'post',
            url: 'drugOrder/submit',
            data: this.params,
            elseData: {
              ajaxLoading: true,
            },
          }).then((res) => {
            console.log('=========', res)
            // if (!res.d && res.data.msg) {
            //   this.$.toast.error(res.data.msg)
            //   return
            // }
            this.getdrugOrderDetail().then(() => {
              this.$.toast.success('提交成功')
              this.state = this.orderDetail.state
              this.params.distributionType = this.orderDetail.distributionType
              this.orderDetail.deliveryId
                ? (this.params.deliveryId = this.orderDetail.deliveryId)
                : delete this.params.deliveryId
              this.orderDetail.pharmacyId
                ? (this.params.pharmacyId = this.orderDetail.pharmacyId)
                : delete this.params.pharmacyId
            })
          })
        }
      } else if (this.state == 50) {
        this.$axios({
          type: 'post',
          url: 'drugOrder/reset',
          data: {
            id: this.id,
          },
          elseData: {
            ajaxLoading: true,
          },
        }).then((res) => {
          this.getdrugOrderDetail().then(() => {
            this.state = this.orderDetail.state
            this.params.distributionType = this.orderDetail.distributionType
            this.orderDetail.deliveryId
              ? (this.params.deliveryId = this.orderDetail.deliveryId)
              : delete this.params.deliveryId
            this.orderDetail.pharmacyId
              ? (this.params.pharmacyId = this.orderDetail.pharmacyId)
              : delete this.params.pharmacyId
          })
        })
      }
    },

    cancleOrder() {
      Dialog.confirm({
        title: '操作确认',
        confirmButtonColor: '#1AB370',
        message: '是否确认取消',
      })
        .then(() => {
          this.$axios({
            type: 'post',
            url: 'drugOrder/cancel',
            data: {
              id: this.id,
            },
            elseData: {
              loading: true,
            },
          }).then((res) => {
            this.getdrugOrderDetail().then(() => {
              this.state = 50
              // this.cancleText = '你已取消订单，购药订单已关闭'
            })
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    handleAgree(payload) {
      this.isAgree = payload
    },
    getPreDetail() {
      this.$axios({
        type: 'post',
        url: 'drugOrder/preDetail',
        data: {
          id: this.$route.query.prescriptionId,
        },
        elseData: {
          ajaxLoading: true,
        },
      }).then((res) => {
        console.log(res)
        this.preDetail = res.d
      })
    },
    changeState() {
      this.getdrugOrderDetail()
    },
    getdrugOrderDetail() {
      return new Promise((resolve) => {
        this.$axios({
          type: 'post',
          url: 'drugOrder/detail',
          data: {
            id: this.id,
          },
          elseData: {
            ajaxLoading: true,
          },
        }).then((res) => {
          console.log(res)
          this.orderDetail = res.d
          this.state = res.d.state
          resolve()
        })
      })
    },

    getAddressList() {
      this.$axios({
        type: 'post',
        url: 'delivery/queryList',
        elseData: {
          loading: true,
        },
        _self: this,
      }).then((res) => {
        console.log(res)
        this.addressList = [...res.d.data]
      })
    },
    closeDrugWayPanelShow(payload) {
      this.drugWayPanelShow = false
      if (payload) {
        console.log(payload)
        this.distributionName = payload.name
        this.params.distributionType = payload.value
      } else {
        return
      }
    },
    pay() {
      wx.miniProgram.redirectTo({
        url: `/pages/pay/pay?id=${this.id}&price=${this.orderDetail.realPrice}&payType=1`,
      })
      // alert(id)
    },
    refreshAddressList() {
      this.getAddressList()
    },

    closeAddressPanel(payload) {
      if (payload) {
        console.log(payload)
        this.selectAddress = payload
        this.addressShow = true
        this.addressListPanelShow = false
        this.deliveryPlaceholder = ''
        this.params.deliveryId = payload.id
        delete this.params.pharmacyId
        console.log('=========', this.params)
      } else {
        this.addressListPanelShow = false
      }
    },
    closePanel() {
      this.addressListPanelShow = false
    },

    toStoreList() {
      this.$router.push({
        name: 'drug.storeList',
        query: {
          prescriptionId: this.$route.query.prescriptionId,
        },
      })
    },
    consoleParams() {
      console.log(this.params)
    },
    checkPre() {
    //   wx.miniProgram.navigateTo({
    //     url: `/pages/protocol/protocol?rpId=${
    //       this.orderDetail.prescriptionId || this.preDetail.prescriptionId
    //     }`,
    //   })
        this.$router.push({
            name:'rp',
            query:{
               rpId:this.orderDetail.prescriptionId || this.preDetail.prescriptionId
            }
        })
    },
    confirmDrug() {
      Dialog.confirm({
        title: '收药确认',
        confirmButtonColor: '#1AB370',
        message: '确认收到药品了吗？',
      })
        .then(() => {
          this.$axios({
            type: 'post',
            url: 'drugOrder/sign',
            data: {
              id: this.id,
            },
            elseData: {
              loading: true,
            },
          }).then((res) => {
            this.getdrugOrderDetail().then(() => {
              this.state = 40
              // this.cancleText = '你已取消订单，购药订单已关闭'
            })
          })
        })
        .catch(() => {
          // on cancel
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/variables.scss';
.wraper {
  display: flex;
  flex-direction: column;
  .detail {
    flex: 1;
    overflow-y: auto;
    .code {
      padding: 18px 0 !important;
      p {
        font-size: 14px;
        color: #333333;
        text-align: center;
      }
      .drug-code {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        margin: 0 auto;
        width: 160px;
        height: 44px;
        background: rgba(26, 179, 112, 0.11);
        border-radius: 24px;
        line-height: 44px;
        color: #1ab370;
      }
    }
    .p {
      padding-top: 4px;
      border-radius: 8px;
      background: #fff;
      margin: 8px;

      /deep/.van-field__label {
        font-size: 14px;
        margin-left: 0;
      }
      /deep/.van-cell__value {
        font-size: 14px;
      }
      .store {
        padding: 8px 16px;
        border-top: 1px solid #fff;
        position: relative;
        margin-top: -1px;
        z-index: 99;
        .hospital-name {
          display: flex;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
            border-radius: 5px;
          }
          span {
            margin-left: 5px;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
          }
        }
        p {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }
      .select-address {
        padding: 8px 16px;
        border-top: 1px solid #fff;
        position: relative;
        margin-top: -1px;
        z-index: 99;
        & > p {
          .name {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
          }
          .phone {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
          }
        }

        .adress {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 17px;
        }
      }
    }
    .p-info,
    .case-info,
    .order-info {
      margin: 0 8px;
      background: #ffffff;
      border-radius: 8px;
      padding: 8px 16px;
      position: relative;
      .price {
        /deep/.van-cell__value {
          color: #ff8200 !important;
          font-weight: bold;
        }
      }
      /deep/.van-cell {
        padding-left: 0px;
        padding-right: 0;
        padding-top: 12px;
        padding-bottom: 12px;
        /deep/.van-cell__title {
          color: #666;
          flex: none;
        }

        .van-cell__value {
          word-wrap: unset;
          color: #333;
        }
      }
      .van-cell::after {
        left: 0;
        right: 0;
        // border: none;
      }

      .noline::after {
        border: none;
      }

      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h1 {
          line-height: 40px;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
        }
        h2 {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 48px;
        }
        .van-button--mini {
          padding: 0 8px;
        }
      }
    }
    .tip {
      padding: 12px 16px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
    }
  }
  .bottom {
    background: #ffffff;
  }
  .btn-group {
    // width: 100%;
    display: flex;
    // margin: 0 16px 30px 16px;
    // padding: 10px 16px 30px 16px;
    // background: #ffffff;
    .btn-order,
    .btn-confirm {
      width: 100%;
      margin: 0 16px 30px 16px;
    }
    .btn-cancle {
      width: 30%;
      margin-right: 10px;
      margin-left: 16px;
      margin-bottom: 30px;
    }
    .btn-pay {
      flex: 1;
      margin-bottom: 30px;
      margin-right: 16px;
    }
  }
}
</style>