<!--
 * @Author: 张博洋
 * @Date: 2021-08-05 18:22:37
 * @LastEditTime: 2022-01-17 11:25:43
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/drug/components/service.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="agreement">
    <div class="ag">
      <van-checkbox v-model="checked"
                    checked-color="#1AB370"
                    shape="square"
                    @change="handleCheckbox"
                    icon-size="15px"
                    class="mr8"></van-checkbox>
      <div>我已阅读并知晓<span v-if="newDistributionType == 10"
              @click="handleAgree('300005')">{{agreeTitle}}</span><span v-if="newDistributionType == 20"
              @click="handleAgree('300006')">{{agreeTitle}}</span></div>
    </div>
  </div>
</template>

<script>
import checkbox from '@/mixin/Checkbox.js'
export default {
  mixins: [checkbox],
  props: ['distributionType'],
  data() {
    return {
      checked: '',
      src: '',
      showAgree: false,
      agreeTitle: '',
      newDistributionType: '',
    }
  },
  watch: {
    distributionType: {
      handler(val) {
        console.log(val)
        this.newDistributionType = val
        if (val == 10) {
          this.agreeTitle = '《到店自取服务协议》'
        } else if (val == 20) {
          this.agreeTitle = '《药品配送服务协议》'
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleCheckbox(val) {
      console.log(val)
      this.$emit('checkboxEvent', val)
    },
    handleAgree(type) {
      this.$router.push({
        name: 'agreement.agreement',
        query: {
          type: type,
        },
      })
      // this.showAgree = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables';
.agreement {
  display: flex;
  align-items: flex-start;
  padding-left: 16px;
  margin-bottom: 15px;
}
.ag {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 17px;
  & > div {
    span {
      font-weight: 400;
      color: color(primary);
    }
  }
}
</style>