<!--
 * @Author: 张博洋
 * @Date: 2021-08-02 13:51:49
 * @LastEditTime: 2022-02-25 14:37:39
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/drug/components/addressListPanel.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <van-popup v-model="show"
             round
             position="bottom"
             close-on-popstate
             @close="closePanel"
             :close-on-click-overlay="true"
             :overlay="true"
             get-container="body"
             class="address-list-panel">
    <div class="title">配送地址</div>
    <div class="address-item"
         v-for="(item,index) in addressList"
         :key="index"
         @click="select(item)">
      <p style="display: flex; flex-wrap: wrap; align-items: center;">
        <span class="name mr12">{{item.userName}}</span>
        <span class="phone">{{item.userPhone}}</span>
      </p>
      <p class="adress mt8">{{item.provinceName + item.cityName + item.countyName}}</p>
      <p class="adress mt2">{{item.address}}</p>
    </div>

    <van-button style="margin-top:24px"
                color="rgba(26, 179, 112, 1)"
                plain
                round
                @click="toAddress">新建收货地址</van-button>
  </van-popup>
</template>
<script>
export default {
  name: 'addressListPanel',
  props: {
    addressList: {
      type: Array,
    },
    addressListPanelShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: this.addressListPanelShow,
    }
  },
  watch: {
    addressListPanelShow(val) {
      console.log(val)
      this.show = val
    },
  },

  created() {},
  activated() {
    this.$emit('refreshAddressList')
  },
  methods: {
    select(item) {
      console.log(item)
      this.$emit('closeAddressPanel', item)
    },
    closePanel() {
      this.$emit('closePanel')
    },

    toAddress() {
      this.$router.push({
        name: 'address.edit',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.address-list-panel {
  display: flex;
  flex-direction: column;
  padding: 12px 30px;
  .title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  .address-item {
    padding: 16px 0;
    border-bottom: 1px solid #f5f5f5;
    & > p {
      .name {
        font-size: 15px;
        font-weight: 500;
        color: #333333;
      }
      .phone {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }

    .adress {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
    }
  }
}
</style>
