/*
 * @Author: 张博洋
 * @Date: 2021-07-22 11:22:30
 * @LastEditTime: 2021-08-05 18:30:09
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/mixin/Checkbox.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import {
  Checkbox, CheckboxGroup
} from 'vant';

export default {
  components: (() => {
    let obj = {};
    obj[Checkbox.name] = Checkbox;
    obj[CheckboxGroup.name] = CheckboxGroup;
    return obj;
  })(),
}
