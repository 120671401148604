<!--
 * @Author: 张博洋
 * @Date: 2021-11-23 15:32:18
 * @LastEditTime: 2021-12-09 14:26:17
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/drug/components/drugStatusCard.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div>

    <div class="card card-unpay"
         v-if="state == 10">
      <p class="status">
        <van-icon name="underway-o"
                  size="24" />
        <span class="ml12">待支付</span>
      </p>
      <p class="tip mt18">请尽快支付，
        <van-count-down :time="payExpireTime"
                        format="mm 分 ss 秒"
                        @finish="changeState" />
        后将自动关闭！
      </p>
      <p class="time mt8">{{$.replyTime(updateTime)}}</p>

    </div>

    <div class="card card-cancle"
         v-if="state == 50">
      <p class="status">
        <span class="iconfont icon-quxiao"></span>
        <span class="ml12">已取消</span>
      </p>
      <p class="tip mt18">{{cancleText}}</p>
      <p class="time mt8">{{$.replyTime(updateTime)}}</p>
    </div>

    <div class="card card-un"
         v-if="state == 20">
      <p class="status">
        <span class="iconfont icon-quxiao"></span>
        <span class="ml12">待打包</span>
      </p>
      <p class="tip mt18">请等待药师打包</p>
      <p class="time mt8">{{$.replyTime(updateTime)}}</p>
    </div>

    <div class="card card-un"
         v-if="state == 30">
      <!-- <div class="btn"
           @click="checkLogistics">查看物流详情</div> -->
      <p class="status">
        <span class="iconfont icon-quxiao"></span>
        <span class="ml12">待取药</span>
      </p>
      <p class="tip mt18">您已支付完成，请前往门店取药。</p>
      <p class="time mt8">{{$.replyTime(updateTime)}}</p>
    </div>

    <div class="card card-un"
         v-if="state == 31">
      <!-- <div class="btn"
           @click="checkLogistics">查看物流详情</div> -->
      <p class="status">
        <span class="iconfont icon-quxiao"></span>
        <span class="ml12">待收药</span>
      </p>
      <p class="tip mt18">您已支付完成，请前往门店取药。</p>
      <p class="time mt8">{{$.replyTime(updateTime)}}</p>
    </div>

    <div class="card card-finsh"
         v-if="state == 40">
      <!-- <div class="btn"
           @click="checkLogistics">查看物流详情</div> -->
      <p class="status">
        <van-icon name="passed"
                  size="24" />
        <span class="ml12">已完成</span>
      </p>
      <p class="tip mt18">您已取药完成，欢迎再来问诊。</p>
      <p class="time mt8">{{$.replyTime(updateTime)}}</p>

    </div>

  </div>
</template>

<script>
import '@/assets/iconfont.css'
export default {
  data() {
    return {
      time: '',
      newState: '',
      newCancleText: '',
    }
  },
  watch: {
    cancleText: {
      handler(val) {
        console.log(val)
        this.newCancleText = val
      },
    },
    state: {
      handler(val) {
        console.log(val)
        this.newState = val
      },
    },
    payExpireTime: {
      handler(val) {
        this.time = val
      },
    },
  },
  created() {
    console.log(this.state)
  },
  props: ['state', 'payExpireTime', 'cancleText', 'updateTime'],
  methods: {
    changeState() {
      // this.newState = 5
      this.$emit('changeState')
    },
    checkLogistics() {},
  },
}
</script>

 <style lang="scss" scoped>
.card-unpay,
.card-un {
  .btn {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 90px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    text-align: center;
    line-height: 24px;
  }
  .van-icon {
    color: #ff8200;
  }
  span {
    color: #ff8200;
    font-size: 24px;
    font-weight: 600;
  }
}
.card-finsh {
  .btn {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 90px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    text-align: center;
    line-height: 24px;
  }
  .van-icon {
    color: #1ab370;
  }
  span {
    color: #1ab370;
    font-size: 24px;
    font-weight: 600;
  }
}
.card-cancle {
  span {
    color: #999999;
    font-size: 24px;
    font-weight: 600;
  }
}
.card-refunding {
  .van-icon {
    color: #ff8200;
  }
  span {
    color: #ff8200;
    font-size: 24px;
    font-weight: 600;
  }
}

.card-refundFail {
  .van-icon {
    color: #ff4d4f;
  }
  span {
    color: #ff4d4f;
    font-size: 24px;
    font-weight: 600;
  }
}
.card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: auto;
  background: #ffffff;
  border-radius: 8px;
  margin: 8px;
  position: relative;
  padding: 20px;
  .status {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tip {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  .time {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }
  .checkPrescription {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 68px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    text-align: center;
    line-height: 24px;
  }
  .icon-ellipsis {
    position: absolute;
    right: 20px;
    top: 10px;
  }
}
.pop {
  position: absolute;
  top: 35px;
  right: 0px;
  color: #333;

  & > div {
    width: 128px;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 20px;
    background-color: #fff;
    box-shadow: 0 2px 12px #e1e1e1;
    border-radius: 4px;
  }
}
.pop:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px;
  color: #fff;
  top: 1px;
  border-top-width: 0;
  border-bottom-color: currentColor;
  box-shadow: 0 2px 12px #e1e1e1;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  right: 10px;
}
</style>