<!--
 * @Author: 张博洋
 * @Date: 2021-08-16 12:06:12
 * @LastEditTime: 2021-11-23 10:30:06
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/drug/components/drugWayPanel.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<!--
 * @Author: 张博洋
 * @Date: 2021-08-09 18:04:53
 * @LastEditTime: 2021-08-16 13:30:36
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/patient/components/sexPanel.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <van-popup v-model="show"
             round
             position="bottom"
             close-on-popstate
             :close-on-click-overlay="true"
             @close="closePanel"
             :overlay="true"
             get-container="body"
             class="way-list-panel">
    <div class="title">选择取药方式</div>
    <div class="id flex-box"
         v-for="(item,index) in list"
         :key="index"
         @click="handleCheck(item,index)">
      <van-icon class="success"
                name="success"
                color="#1AB370"
                size="20"
                v-if="item.isChecked === 1" />
      {{item.name}}
    </div>

  </van-popup>
</template>
<script>

export default {
  props: {
    drugWayPanelShow: Boolean
  },
  data () {
    return {
      show: this.drugWayPanelShow,
      checkedItem: {},

      list: [{
        name: '药店自提',
        value: 10,
        isChecked: 0
      },
      {
        name: '药店配送',
        value: 20,
        isChecked: 0
      }]
    };
  },
  watch: {
    drugWayPanelShow (val) {
      this.show = val;
    }
  },
  methods: {
    closePopup () {
      console.log("关闭弹窗");
      console.log(this.checkedItem)
      this.$emit("close", this.checkedItem);
    },
    closePanel () {
      this.$emit("close");
    },
    handleCheck (item, index) {
      this.checkedItem = item
      this.list.forEach(i => {
        console.log(i.isChecked)
        i.isChecked = 0
      })
      this.list[index].isChecked = 1
      setTimeout(() => {
        this.closePopup()
      }, 100)
    },
  }
};
</script>
<style lang="scss" scoped>
.way-list-panel {
  display: flex;
  flex-direction: column;
  padding: 12px 32px;
  .title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
  .id {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    position: relative;
    border-bottom: 1px solid #f5f5f5;
    & > div {
      align-items: center;
    }
    .success {
      position: absolute;
      right: 0;
    }
  }
  .id:last-child {
    border: none;
  }
}
</style>
